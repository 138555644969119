import QueryString from 'query-string'
import {isString} from '@cullylarson/f'

export function paramUrl(pathname, search) {
    const queryString = isString(search)
        ? search
        : QueryString.stringify(search)

    return [
        pathname.trim().replace(/\?+$/, ''),
        queryString.trim().replace(/^\?+/, ''),
    ]
        .filter(x => !!x)
        .join('?')
}

export function getCurrentBaseUrl() {
    if(!location) return null

    return [
        location.protocol,
        '//',
        location.host,
    ].join('')
}

// https://gist.github.com/max10rogerio/c67c5d2d7a3ce714c4bc0c114a3ddc6e
export function slugify(...args) {
    return args
        .join(' ')
        .normalize('NFD') // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, '-') // separator
}
