import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
export function getOffset(page, perPage) {
  if (page < 1 || !page) page = 1;
  if (perPage < 0 || !perPage) perPage = 0;
  return (page - 1) * perPage;
}
export var ELLIPSIS = function ELLIPSIS(replacesPage) {
  return {
    isEllipsis: true,
    key: 'key--' + replacesPage
  };
};
export function getPagesToRender(currentPage, totalPages) {
  var numLeft = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 4;
  var numRight = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 4;
  var numAround = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 2;

  var getPagesLeft = function getPagesLeft() {
    var pages = []; // eslint-disable-next-line no-var

    for (var i = 1; i <= numLeft; i++) {
      if (i === currentPage) break;
      pages.push(i);
    }

    if (i < currentPage - numAround) {
      pages.push(ELLIPSIS(i));
    }

    return pages;
  };

  var getPagesRight = function getPagesRight() {
    var pages = [];
    var startAt = totalPages - numRight + 1;

    if (startAt > currentPage + numAround + 1) {
      pages.push(ELLIPSIS(startAt - 1));
    }

    for (var i = startAt; i <= totalPages; i++) {
      if (i <= currentPage) continue;
      pages.push(i);
    }

    return pages;
  };

  var getPagesAround = function getPagesAround() {
    var pages = [];

    for (var i = currentPage - numAround; i <= currentPage + numAround; i++) {
      if (i <= 0) continue;
      if (i > totalPages) break;
      pages.push(i);
    }

    return pages;
  };

  return _toConsumableArray(new Set([].concat(_toConsumableArray(getPagesLeft()), _toConsumableArray(getPagesAround()), _toConsumableArray(getPagesRight()))));
}